<script>
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { usePurchaseCouponBannerStore } from './store'
import PurchaseCouponBannerView from './view.vue'
import { vOnVisible as onVisible } from './directives'

export default defineComponent({
  name: 'PurchaseCouponBanner',
  components: {
    PurchaseCouponBannerView,
  },
  directives: {
    onVisible,
    expose,
    tap,
  },
  props: commonProps,
  setup(props) {
    return {
      ...usePurchaseCouponBannerStore(props),
    }
  },
})
</script>

<template>
  <div
    v-show="isShowHomePageBanner"
    v-tap="formatAnalysisData('2-22-2', purchaseCouponInfo)"
    v-expose="formatAnalysisData('2-22-1', purchaseCouponInfo)"
    v-on-visible="onContainerVisible"
    class="purchase-coupon-banner"
  >
    <PurchaseCouponBannerView
      using-scene="homepage"
      :purchase-coupon-info="purchaseCouponInfo"
      :languages="languages"
      @cart-checkout="jumpCartCheckout"
    />
  </div>
</template>

<style lang="less">
</style>
